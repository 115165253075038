/* --------------------------------------------------
 * Resume Download
 * --------------------------------------------------
 */
 .about {
    display: flex;

    .profile-img{
        flex: 50%;

        .profile{
            display: block;
            object-fit: cover;
            object-position: 50% 50%;
            margin: auto;
            aspect-ratio: 1/1;
            padding: 2rem;
        }
    }

    .info{
        flex: 50%;
        padding-top: 1.5rem;
    }
 }
/* --------------------------------------------------
 * Resume Download
 * --------------------------------------------------
 */
.resume-download a .button{
    width: 100%;
    max-width: 100%;
    height: 2.5rem;
}

.resume-download a:hover .button{
    background-color: #fe5186;
    color: #fff;
}

/* --------------------------------------------------
 * Section Titles
 * --------------------------------------------------
 */
.employment-history .head h3,
.education-history .head h3,
.training-history .head h3,
.skills-boxed .head h3,
.extracarricular-history .head h3,
.notable-projects-history .head h3 {
    color: #fe5186;
    display: flex;
}

.employment-history .head h3 .icon,
.education-history .head h3 .icon,
.training-history .head h3 .icon,
.skills-boxed .head h3 .icon,
.extracarricular-history .head h3 .icon,
.notable-projects-history .head h3 .icon{
    flex: 5%;
}

.employment-history .head h3 .title,
.education-history .head h3 .title,
.training-history .head h3 .title,
.skills-boxed .head h3 .title,
.extracarricular-history .head h3 .title,
.notable-projects-history .head h3 .title{
    flex: 95%;
}

.accordian-title {
    margin-left: -5%;
}

/* --------------------------------------------------
 * Enteries
 * --------------------------------------------------
 */
.employment-entry,
.education-entry,
.training-entry,
.extracarricular-entry,
.notable-project-entry {
    padding-bottom: 1rem;
}

.employment-entry .meta,
.education-entry .meta,
.training-entry .meta,
.extracarricular-entry .meta,
.notable-project-entry .meta {
    display: flex;
}

.employment-entry .meta .position,
.education-entry .meta .school,
.training-entry .meta .title,
.extracarricular-entry .meta .title,
.notable-project-entry .meta .title {
    flex: 65%;
}

.employment-entry .meta .position h4,
.education-entry .meta .school h4,
.training-entry .meta .title h4,
.extracarricular-entry .meta .name h4,
.notable-project-entry .meta .title h4 {
    margin: 0;
}

.employment-entry .meta .date,
.education-entry .meta .date,
.training-entry .meta .date,
.extracarricular-entry .meta .date,
.notable-project-entry .meta .date {
    flex: 35%;
    font-size: small;
    font-style: italic;
    text-align: right;
}

 /* --------------------------------------------------
 * Skills
 * --------------------------------------------------
 */
.skills-boxed .enteries {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    
    .skill-entry{
        flex: 25%;
        max-width: 30%;
        margin: 2%;
        padding: 2%;
        border: 1px solid;

        .title {
            text-align: center;
        }
    }
}

 /* --------------------------------------------------
 * Accordian
 * --------------------------------------------------
 */
.enteries-long {
   max-height: 0;
   overflow: hidden;
}

.accordian-active .enteries-short {
   max-height: 0;
   overflow: hidden;
}

.accordian-active .enteries-long {
   max-height: none;
   overflow: hidden;
}

.head h3 .icon::after {
   content: "+";
}
.accordian-active .head h3 .icon::after {
   content: "-"
}

 /* --------------------------------------------------
 * Links
 * --------------------------------------------------
 */
 .links {
   display: block;
 }
 .links ul{
   list-style-type: none;
   display: flex;
   justify-content: center;
 }

 .links ul li{
   padding: 0.3rem;

 }

 .links ul li a{
   display: block;
 }

 .links ul li a i{
   font-size: xx-large;
   color: #a9a9b3;
 }

 .links ul li a:hover i{
   color:#fe5186;
 }